import rounding from '@/util/rounding'
import { apiQueryRate_USD_VND } from '@/resource'

export default {
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (!Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })))
      } else if (this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        )
      } else {
        callback()
      }
    }
    return {
      zotaForm: {
        accountNumber: '',
        amount: '',
        notes: '',
      },
      zotaRules: {
        accountNumber: [
          {
            required: true,
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
      },
      rate: 0.0,
      maxLimit: 300000000,
      bankHTML: '',
    }
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.zotaForm.amount, 0)
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2)
    },
  },
  mounted() {
    this.queryRate()
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.zotaForm.accountNumber = accountNumber
    },
    queryRate() {
      apiQueryRate_USD_VND()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data
        })
        .catch(err => {
          this.errorMessage(this.$t('deposit.viet.result.rateError'))
        })
    },
  },
}
