<template>
  <div class="content">
    <div class="info_box">
      <img src="@/assets/images/channel/local_transfer.png" alt="" />
      <p>{{ $t('deposit.vietZota.desc') }}</p>
      <ul v-html="$t('deposit.vietZota.inst')"></ul>
    </div>
    <div class="form_main">
      <p class="title">{{ $t('deposit.vietZota.form.header') }}</p>
      <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="zotaRules">
        <div class="form_box">
          <ul class="clearfix">
            <li class="fl">
              <AccountNumber
                supportedCurrencies="USD"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input v-model="zotaForm.amount" :currency="accountCurrency" :precision="2"></numeric-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="clearfix">
            <li class="fl">
              <el-form-item :label="$t('common.field.imptNotes')">
                <el-input v-model="zotaForm.notes" data-testid="applicationNotes"></el-input>
              </el-form-item>
            </li>
            <li class="fr">
              <p class="mb-2">
                <span class="required_icon">*</span>
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'VND' }) }}
                <span>{{ rate }}</span>
              </p>
              <p>
                <span class="required_icon">*</span> VND: <span>{{ rateChange }}</span>
              </p>
            </li>
          </ul>
        </div>
        <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
          {{ $t('common.button.submit') }}
        </el-button>
      </el-form>
      <div v-html="bankHTML"></div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import vietnamMixin from '@/mixins/page/deposit/vietnam';
import { apiXpay_payment } from '@/resource';
import { autoForm } from '@/util/getAutoForm';

export default {
  name: 'VietnamXpay',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, vietnamMixin],
  methods: {
    submitForm() {
      this.$refs['zotaForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                this.bankHTML = autoForm(result.data.msg);
                if (this.bankHTML.length > 0) {
                  this.$nextTick(function () {
                    if (document.getElementById('AutoForm')) {
                      document.getElementById('AutoForm').submit();
                    } else {
                      this.loading = false;
                      this.errorMessage(this.$t('deposit.default.failed'));
                    }
                  });
                } else {
                  this.loading = false;
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                if (result.data.code !== 562) this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiXpay_payment(
        {
          mt4Account: this.zotaForm.accountNumber,
          operateAmount: this.zotaForm.amount,
          applicationNotes: this.zotaForm.notes,
          depositAmount: this.rateChange,
          rate: this.rate
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
