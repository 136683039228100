import { apiEeziePay_payment } from '@/resource'
import { autoForm } from '@/util/getAutoForm'
export default {
  data() {
    return {
      bankHTML: '',
    }
  },
  methods: {
    submitEeeziePayForm() {
      this.$refs['eeziePayForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.submitDeposit()
            .then(result => {
              this.eeziePayhandling(result)
            })
            .catch(err => {
              this.loading = false
              this.errorMessage(this.$t('deposit.default.failed'))
            })
        } else {
          return false
        }
      })
    },
    eeziePayhandling(result) {
      if (result.data.code == 0 && result.data.data) {
        this.bankHTML = autoForm(result.data.msg)
        if (this.bankHTML.length > 0) {
          this.$nextTick(function () {
            if (document.getElementById('AutoForm')) {
              document.getElementById('AutoForm').submit()
            } else {
              this.loading = false
              this.errorMessage(this.$t('deposit.default.failed'))
            }
          })
        } else {
          this.loading = false
          this.errorMessage(this.$t('deposit.default.failed'))
        }
      } else {
        this.loading = false
        if (result.data.code !== 562) {
          this.errorMessage(this.$t('deposit.default.failed'))
        }
      }
    },
    submitDeposit() {
      // To be Confirm
      return apiEeziePay_payment(
        {
          mt4Account: this.eeziePayForm.accountNumber,
          operateAmount: this.eeziePayForm.amount,
          applicationNotes: this.eeziePayForm.notes,
          depositAmount: this.rateChange,
          rate: this.rate,
        },
        this.token
      )
    },
  },
}
