<template>
  <div id="vietnamZotapay">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="form_deposit">
          <h4>{{ $t('deposit.vietZota.header') }}</h4>
          <div class="info_box_choose">
            <el-button
              v-for="(item, index) in filteredOptions"
              :key="item.name"
              @click="handleSelectIndex(item.value)"
              :class="elButtonClassName(paymentChannel === item.value)"
              :data-testid="item.name"
            >
              {{ sequence[index] }}<br />
            </el-button>
          </div>
          <component :is="currentComponent" ref="child"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Xpay from '@/components/deposit/Vietnam/Xpay';
import EeziePay from '@/components/deposit/Vietnam/EeziePay';
import sequenceMixin from '@/mixins/sequence';
import multiplePaymentMixin from '@/mixins/page/deposit/multiplePayment';

export default {
  name: 'VietnamPayment',
  components: { Xpay },
  mixins: [sequenceMixin, multiplePaymentMixin],
  data() {
    return {
      //1:ZOTAPAY,2:Xpay,3:PaymentAsia,4:EeziePay
      paymentChannel: null,
      currentComponent: null,
      paymenAsiaRegulator: ['vfsc'],
      eeziePayRegulator: ['vfsc'],
      options: [
        { name: 'EeziePay', value: 4 },
        { name: 'Xpay', value: 2 }
      ]
    };
  },
  computed: {
    filteredOptions: function () {
      const options = this.options.filter(item => this.displayByRegulator(item));
      if (!this.paymentChannel) {
        this.paymentChannel = options[0].value;
        this.handleSelectIndex(this.paymentChannel);
      }
      return options;
    }
  },
  methods: {
    handleSelectIndex(i) {
      this.paymentChannel = i;
      if (i === 2) {
        this.currentComponent = Xpay;
      } else if (i === 4) {
        this.currentComponent = EeziePay;
      }
    },
    displayByRegulator(option) {
      const value = option.value;

      // Restrict Cambodia user to see EeziePay Option
      if (value === 4 && parseInt(this.$store.state.common.countryCode) !== 6991) {
        return false;
      }

      if (value === 4 && !this.eeziePayRegulator.includes(this.regulator)) {
        return false;
      }

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/components/deposit/multipleButton.scss';
</style>
