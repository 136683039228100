<template>
  <div class="content">
    <div class="info_box">
      <img src="@/assets/images/channel/local_transfer.png" alt="" />
      <p>{{ $t('deposit.vietZota.desc') }}</p>
      <ul>
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst2', { number: '2', country: $t('deposit.vietnam.country') }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
        <li>{{ $t('deposit.method.inst4', { number: '4' }) }}</li>
        <li>
          {{
            $t('deposit.method.inst8', {
              number: '5',
              payment: 'QR/Momopay/Zalopay',
              amount: new Intl.NumberFormat(this.$options.filters.googleLangSwitch(this.$store.state.common.lang), {
                style: 'decimal'
              }).format(20000000),
              currency: $t('common.currencyVND')
            })
          }}
        </li>
      </ul>
    </div>
    <div class="form_main">
      <p class="title">{{ $t('deposit.vietZota.form.header') }}</p>
      <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="zotaRules">
        <div class="form_box">
          <ul class="clearfix">
            <li class="fl">
              <AccountNumber
                supportedCurrencies="USD"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input v-model="zotaForm.amount" :currency="accountCurrency" :precision="2"></numeric-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="clearfix">
            <li class="fl">
              <el-form-item :label="$t('common.field.imptNotes')">
                <el-input v-model="zotaForm.notes" data-testid="applicationNotes"></el-input>
              </el-form-item>
            </li>
            <li class="fr">
              <p class="mb-2">
                <span class="required_icon">*</span>
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'VND' }) }}
                <span>{{ rate }}</span>
              </p>
              <p>
                <span class="required_icon">*</span> VND: <span>{{ rateChange }}</span>
              </p>
            </li>
          </ul>
        </div>
        <el-button :loading="loading" @click="submitEeeziePayForm()" data-testid="submit">
          {{ $t('common.button.submit') }}
        </el-button>
      </el-form>
      <div v-html="bankHTML"></div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import vietnamMixin from '@/mixins/page/deposit/vietnam';
import eeziePayMixin from '@/mixins/page/deposit/eeziePay';

export default {
  name: 'VietnamEeziePay',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, vietnamMixin, eeziePayMixin],
  data() {
    return {
      maxLimit: 300000000
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
